import React from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import tw from "twin.macro"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import Layout from "../layouts/base"
import SEO from "../components/seo"

const Wrapper = tw.div``

const BlogPostTemplate = ({ data: { previous, next, post }, pageContext: { blogUri } }) => {
	const featuredImage = {
		fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
		alt: post.featuredImage?.node?.alt || ``,
	}

	return (
		<Layout>
			<SEO title={post.title} description={post.excerpt} />

			<article className="container" tw="my-12 md:my-16 xl:my-20" itemScope itemType="http://schema.org/Article">
				<header>
					<h1 itemProp="headline">{parse(post.title)}</h1>

					{/* if we have a featured image for this post let's display it */}
					{featuredImage?.fluid && (
						<div tw="my-4 md:my-8 lg:my-12">
							<Image tw="rounded-xl" fluid={featuredImage.fluid} alt={featuredImage.alt} />
						</div>
					)}
				</header>

				{!!post.content && (
					<section itemProp="articleBody" className="prose">
						{parse(post.content)}
					</section>
				)}
			</article>

			<nav
				className="container"
				tw="my-12 md:my-16 xl:my-20 pt-4 md:pt-8 lg:pt-12 border-t-2 border-b-0 border-catskill"
			>
				<ul tw="flex flex-wrap justify-between list-none">
					<li>
						{previous && (
							<Link to={`${blogUri}${previous.uri}`} rel="prev">
								← {parse(previous.title)}
							</Link>
						)}
					</li>

					<li>
						{next && (
							<Link to={`${blogUri}${next.uri}`} rel="next">
								{parse(next.title)} →
							</Link>
						)}
					</li>
				</ul>
			</nav>
		</Layout>
	)
}

export default BlogPostTemplate

export const pageQuery = graphql`
	query BlogPostById(
		# these variables are passed in via createPage.pageContext in gatsby-node.js
		$id: String!
		$previousPostId: String
		$nextPostId: String
	) {
		# selecting the current post by id
		post: wpPost(id: { eq: $id }) {
			id
			excerpt
			content
			title
			date(formatString: "MMMM DD, YYYY")
			seo {
				metaTitle
				metaDescription
			}

			featuredImage {
				node {
					altText
					localFile {
						childImageSharp {
							fluid(maxWidth: 1000, maxHeight: 384) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
			}
		}

		# this gets us the previous post by id (if it exists)
		previous: wpPost(id: { eq: $previousPostId }) {
			uri
			title
		}

		# this gets us the next post by id (if it exists)
		next: wpPost(id: { eq: $nextPostId }) {
			uri
			title
		}
	}
`
